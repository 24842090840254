// page
.cr-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  // .row {
  //   // stylelint-disable-next-line
  //   .col,
  //   > [class^='col-'] {
  //     margin-bottom: 1rem;
  //   }
  // }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
