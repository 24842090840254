// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": #5A46AA,
  "secondary": #FF885C,
  "tertiary": #FFDE7B,
  "quaternary": #A1AAD9,
  "quinary": #3D8F7F,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "rejected": #ff9785,
  "approved":#abf8ac,
  "consumable":#6a82fb16,
  "disabled": #a3a4a5
);

:root {
  --primary: #5A46AA !important;
  --secondary: #FF885C !important;
}

$enable-gradients: false;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;

@import "~bootstrap/scss/bootstrap";