.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ql-container {
  min-height: 100px !important;
  
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.max-height-auto{
  max-height: fit-content;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-snow .ql-editor {
  font-size: medium;
  min-height: 200px !important;
  max-height: 500px;
}

.ql-bubble .ql-editor {
  font-size: medium;
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 0.3em;
}

.is-sticky {
  position: fixed;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.order-detail-modal {
  width: 90vw !important;    /* Occupy the 90% of the screen width */
  max-width: 90vw;
} 

.all-paid {
  background-color: #dbead8 !important;
}

.order-flag {
  background-color: #dbead8 !important;
}

.order-red-flag {
  background-color: #ffb2b2 !important;
}

.h1 {
    font-size: 35px;
}

.date-range-full-width {
  width: 100%;
}

.rdrMonths {
  display: flex;
  justify-content: center;
}