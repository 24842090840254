.cr-widget {
  display: flex;
  flex-direction: row;

  &__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  }
}

.basic-100-multi-select {
  width: 100%;
}

.planner-container {
  height: calc(100vh - 150px); /* Full viewport height */
  overflow: hidden; /* Prevents the body from scrolling */
}
.left-planner, .right-planner {
  height: calc(100vh - 150px);
  overflow-y: auto; /* Enables independent scrolling */
  overflow-x: hidden; /* Enables independent scrolling */
  padding: 0px;
}
.custom-hover:hover {
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}
