.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}
.truncated-collapse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  white-space: normal; /* Adjust according to your needs */
  position: relative; /* Required for pseudo-element positioning */
}

.truncated-collapse::after {
  content: '... show more'; /* Customize text here */
  color: var(--blue); /* Customize the color */
  position: absolute; /* Position it properly */
  right: 0; /* Align to the right */
  bottom: 0; /* Adjust vertical alignment */
  background: white; /* Background to make it readable over content */
  padding: 0 5px; /* Adjust padding */
  cursor: pointer;
}

.truncated-collapse:hover::after {
  text-decoration: underline; /* Add hover effect if needed */
}

/* Adjust the full-text-collapse style if needed */
.full-text-collapse {
  display: block;
}

.toggle-text-collapse {
  cursor: pointer;
  color: blue;
  margin-top: 10px;
  display: block;
}
